import React, {Component} from 'react';
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from '@stripe/stripe-js';
import Header from './components/header.js';
import Footer from './components/footer.js';
import Donate from './components/donate.js';
import DonationOptions from './components/donation-options.js';
import LearnMoreButton from './components/learn-more.js';
import SocialComponent from './components/social-component.js';
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from './images/hero_image.png';
import './App.scss';
const data = require('./data/data.json');

class App extends Component {

  renderBody = () => {
		return (
			<div>
				{data.body.map((paragraph) => {
					return (
						<p>{paragraph}</p>
					);
				})}
			</div>
		);
  };

  render() {
    return (
      <div>
      <Header/>
      <div className="row">
        <div className="topTitle">
          <h1>{data.title}</h1>
        </div>
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                  <div className="section-main-title">
                    <img src={heroImage}></img>
                    <div style={{minWidth: "300px"}}></div>
                  </div>

                  <div className="section-main-description">
                    <p>{this.renderBody()}</p>
                    <ul style={{ listStyleType: "none", padding: "0" }}>
                      <li>Earthworks</li>
                      <ul>
                        <li>Earthworks holds the oil, gas and mining industries accountable for the environmental damage they cause while protecting clean air, water, public health and people's rights. </li>
                      </ul>

                      <li>Union of Concerned Scientists</li>
                      <ul>
                        <li>UCS is scientists and citizens putting independent science to work to combat global warming, reduce nuclear proliferation, promote renewable energy, and make healthy food available for all.</li>
                      </ul>

                      <li>Earthjustice</li>
                      <ul>
                        <li>Earthjustice wields the power of law to preserve magnificent places and wildlife, protect people's health, advance clean energy and combat climate change-without charging clients.</li>
                      </ul>

                      <li>Alaska Conservation Foundation  </li>
                      <ul>
                        <li>Alaska Conservation Foundation works to protect Alaska's natural environment by building resilience to climate change and defending public lands and waters.</li>
                      </ul>

                      <li>West Harlem Environmental Action (WE ACT)</li>
                      <ul>
                        <li>WE ACT advocates for environmental justice and creates healthy communities at the New York City, New York State, and federal.</li>
                      </ul>

                      <li>Delta Institute</li>
                      <ul>
                        <li>Delta Institute collaborates with communities throughout the Midwest to solve complex environmental challenges.</li>
                      </ul>
                    </ul>
                  </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <Donate/>
              <DonationOptions stripeAction={this.showModal}/>
              <SocialComponent/>
              <div class="section-donate-disclaimer">*While we work to ensure accuracy, the stats indicated on this page may not be an exact reflection of actual activity.</div>
            </div>
          </div>
      </div>
      <Footer/>
      </div>
    );
  }
}

export default withRouter(App);
